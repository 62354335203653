import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import AccordionBlog from '../Element/AccordionBlog';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import VideoPopup from '../Element/VideoPopup';
//Images
import bnr1 from './../../images/line2.png';
import bnr2 from './../../images/background/bg5.jpg';
import bgimg2 from './../../images/background/bg1.jpg';
import about from './../../images/about/about-2.jpg';
import about1 from './../../images/about/about-1.jpg';
import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery6 from './../../images/gallery/pic6.jpg';

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)

}
const galleryBlog = [
	{image: gallery1},	
	{image: gallery2},	
	{image: gallery3},	
	{image: gallery4},	
	{image: gallery5},	
	{image: gallery6},	
];

function BackgroundBlog(){
	return(
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"  style={{visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn"}}>
						<div className="section-head text-center">
							<div className="video-play">
								{/* <VideoPopup /> */}
							</div>
							<h2>Let Your Kids Have an Amazing<br/>Time at the Park</h2>
							<Link to={"./contact-us"} className="btn btn-md radius-xl">Read More</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function EducationBanner() {
	return (
		<>
			<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
				<div className="about-overlay-box"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="section-head">
								<h2 className="head-title text-yellow">Education from birth<br />begins with us</h2>
								<p className="text-white">Every Child is unique & gift of god with lots of potential & promise. It’s our vision to create & build a strong & idea basement to all the little kids to build the strong future.</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
					</div>
				</div>
			</div>
		</>
	)
}

class AboutUs1 extends Component{
	componentDidMount(){
		var splitBox = document.querySelectorAll('.split-box');
       
		var fSB = [].slice.call(splitBox);
		
		fSB.forEach(el => el.classList.add('split-active'));
	}
	render(){
		return(
			<Fragment>
				<Header />	
				 <div className="page-content">
					<PageTitle  motherMenu="About Us"  activeMenu="About Us" />
					<div className="content-block">
						<div className="section-full bg-white content-inner-2 about-area" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 col-sm-12 m-b15">
										<div className="section-head">
											<h2 className="head-title text-secondry">About School</h2>
											<p>
												Khushi Gurukulam is a unique preschool with a difference. We at Khushi Gurukulam put our lifetime learning experience in terms of discipline life, skills, self-motivation, independence and learn together along with kids.
												<p></p>
												We ensure the kids come out with flying colors and strong understanding with the basics of education instead of just mechanical & monotonous learning. We at Khushi Gurukulam give our kids the practical learning through motor skills, personality’s development & life skills.
												<p></p>
												We live honesty & teach honesty, sense of responsibilities, patience and other behavioral skills to the young learners is equally important as their formal education.
												<p></p>
												These skills help the young learners to differentiate between right & wrong and in turn build a strong moral & ethical structure.
											</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about} alt=""/>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-sm-12 m-t40 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about1} alt="" />
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 m-t60">
										<div className="section-head">
											<h2 className="head-title text-secondry">About the Founder</h2>
											<p>
												Bhargavi has done BE in EC stream in 2012 . Is certified corporate trainer for behavioural and personality development trainer, completed teacher's training in 2014 and started a Preschool Khushi Gurukulam to have the pride of teaching the kids with life skills and independence for life.
											</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>
									
								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{backgroundImage:"url(" + bnr2 + ")"}}>
							<BackgroundBlog />
						</div>
						{/*  Portfolio  */}
						{/* <div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Gallery of our Classes</h2>
									<p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p>
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((data,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 "  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1"> 
																	<img src={data.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage imageToOpen={index} /> 
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>	
							</div>
						</div> */}
						
						<EducationBanner />
						{/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
					</div>
				</div>	
				<Footer />	
			</Fragment>
		)
	}
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;