import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Slider from "react-slick";
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/pic1.jpg';
import team2 from './../../images/team/pic2.jpg';
import team3 from './../../images/team/pic3.jpg';
// import team4 from './../../images/team/pic4.jpg';

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link onClick={() => openLightbox(props.imageToOpen)} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)
}

const teamBlog = [
	{ image: team3, }, { image: team1, }, { image: team2, }, 
];

class Teachers extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render() {
		var settings = {
			arrows: false,
			slidesToShow: 3,
			infinite: true,
			autoplay: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};

		return (
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr1 + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>About the Teachers</h2>
									<p>
										We have an excellent teacher to child ratio at our Preschool to ensure that each child receives the attention he or she needs
									</p>
								</div>
								<div >
									{/* {teamBlog.map((data,index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6" key={index}>
												 <div className="dlab-box box-frame1 team-box m-b40">
													<div className="dlab-thum"> 
														<Link to={"/teachers-details"}>
															<img src={data.image} alt="" />
														</Link>
														<div className="overlay-bx">
															<h5 className="team-title"><Link to={"/teachers-details"}>Jone Doe</Link></h5>
															<span className="team-info">Teachers</span>
															<ul className="list-inline">
																<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-google-plus"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										))} */}

									<SimpleReactLightbox>
										<SRLWrapper >
											<div className="sprite-nav">
												<Slider className="gallery-carousel owl-carousel owl-theme dots-none " ref={c => (this.slider = c)} {...settings}>
													{teamBlog.map((item, index) => (
														<div className="item p-3" key={index}>
															{/* <div className="dlab-box frame-box">
																<div className="dlab-thum dlab-img-overlay1">
																	<img src={item.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon">
																			<Iconimage />
																		</div>
																	</div>
																</div>
															</div> */}
															<img src={item.image} alt="" />
														</div>
													))}
												</Slider>
												<div className="owl-nav" style={{ textAlign: "center" }}>
													<div className=" owl-prev " onClick={this.previous} />
													<div className="owl-next " onClick={this.next} />
												</div>
											</div>
										</SRLWrapper>
									</SimpleReactLightbox>

								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;